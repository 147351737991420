import { BrandOcelotConfig } from '@/types/ocelotConfig';

const ElleConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:rbo4s511gwm',
    },
    placeholderImage: 'https://files-test.elle.se/uploads/2019/05/ellelogo-2.png',
  },
  analytics: {
    brandName: 'elle',
    google: {
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 149478884,
      hostname: 'www.elle.se',
    },
    parsely: {
      siteId: 'elle.se',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_elle',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_elle.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    strossle: {
      enabled: true,
      id: 'aaf86090-a063-476f-8ce0-6d8121847d9c',
    },
    login: {
      enabled: true,
      brandName: 'elle__se',
    },
    infinityScrollArticle: {
      enabled: true,
    },
    infinityScrollFront: {
      title: 'Senaste från ELLE',
    },
    saveArticle: {
      enabled: true,
    },
  },
  pages: {
    author: {
      description:
        'Här hittar du alla skribenter och redaktörer som jobbar på ELLE. ELLEs journalister är experter inom sina områden – allt från mode och beauty till livsstil, nöje och inredning – de ger dig allt du behöver veta om det som gäller just nu!',
    },
    instagram: {
      enabled: false,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Elle Sverige',
      foundingDate: '1945-01-01',
      alternateName: ['Elle', 'elle.se'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/Elle',
        'https://www.wikidata.org/wiki/Q78901556',
        'https://www.pinterest.se/ellesverige/',
        'https://www.youtube.com/user/ellesverige',
        'https://www.facebook.com/ellesverige/',
        'https://www.instagram.com/ellesverige/',
        'https://www.tiktok.com/@ellesverige',
      ],
      webPages: [
        '/mina-sidor',
        '/member',
        '/nyhetsbrev',
        '/kontakt',
        '/om-oss',
        '/om-cookies',
        '/alla-amnen',
        '/vara-skribenter',
      ],
    },
  },
  ads: {
    video: {
      clickToPlay: 'hpHCqkob',
      autoPlay: 'RBpAHqAL',
      videoPage: 'UbenDR0O',
    },
  },
  cmp: {
    onetrust_id: '6420395b-3b08-4929-b9c2-95d729ef1a3a',
  },
};

export default ElleConfig;
