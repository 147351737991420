import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import ElleLogo from '@/elle/public/image/elle-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const currentYear = new Date().getFullYear();

const logo: StandaloneFooterProps['logo'] = <ElleLogo width={133} height={43} />;

const description: StandaloneFooterProps['description'] = (
  <>
    ELLE är den största medieplattformen för Sveriges trendmedvetna kvinnor och väljer ut, tolkar och presenterar det
    senaste och bästa inom mode, beauty, populärkultur och livsstil.
  </>
);

const editors: StandaloneFooterProps['editors'] = `Ansvarig utgivare: Cia Jansson`;

const columns: StandaloneFooterProps['columns'] = (
  <>
    <Footer.Column>
      <Footer.List.Title>Om Elle</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/nyhetsbrev" content="Nyhetsbrev" aria-label="Länk till att prenumerera på Elles nyhetsbrev" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/member" content="ELLE Member" aria-label="Länk till elles medlemskaps-sida" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/alla-amnen"
            content="Alla ämnen"
            aria-label="Länk till sida där alla kategorier på Elle listas i alfabetisk ordning"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/vara-skribenter"
            content="Våra skribenter"
            aria-label="Länk till sida där Elles alla skribenter listas"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/om-cookies" content="Om Cookies" aria-label="Länk till sida om hur cookies hanteras på Elle " />
        </Footer.List.Item>
        <Footer.List.Item>
          <button
            type="button"
            onClick={showOneTrustConsent}
            aria-label="Knapp för att öppna dina inställningar för cookies på Elle"
          >
            Hantera preferenser
          </button>
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://www.aller.se/integritetspolicy/"
            target="_blank"
            content="Integritetspolicy"
            aria-label="Länk till information om Aller Medias integritetspolicy"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://www.aller.se/ai-policy/"
            target="_blank"
            content="Aller Medias AI-policy"
            aria-label="Länk till Aller Medias AI-policy"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-ellese/10285564"
            content="Kommersiell policy ELLE"
            aria-label="Länk till ELLEs kommersiella policy"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Elle.se</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/mode" content="Mode" aria-label="Länk till kategorin mode" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/beauty" content="Beauty" aria-label="Länk till kategorin beauty" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/decoration" content="Decoration" aria-label="Länk till kategorin decoration" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/mat-och-vin" content="Mat & Vin" aria-label="Länk till kategorin mat och vin" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/video" content="Video" aria-label="Länk till Elles video-sida" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/bloggar" content="Blogg & Podd" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Kontakta oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link
            href="/kontakt"
            content="Kontakta oss"
            aria-label="Länk till sida med information om hur du kan kontakta Elle i olika ärenden"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/om-oss" content="Om oss" aria-label="Länk till sida om Elle och dess historia" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://www.ocast.com/se/elle"
            target="_blank"
            content="Annonsera hos oss"
            aria-label="Länk till information om du vill annonsera på Elle"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se"
            target="_blank"
            content="Prenumerera"
            aria-label="Länk till att prenumera på Allers alla olika tidningar"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se/tidningar/elle/"
            target="_blank"
            content="ELLE"
            aria-label="Länk till att prenumerera på Elle"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se/tidningar/elle-decoration/"
            aria-label="Länk till att prenumerera på Elle Decoration"
            target="_blank"
            content="ELLE Decoration"
          />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="https://prenumerera.se/tidningar/elle-mat--vin/"
            target="_blank"
            content="ELLE Mat & Vin"
            aria-label="Länk till att prenumerera på Elle mat och vin"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>
  </>
);

const copyright: StandaloneFooterProps['copyright'] = (
  <span>
    För prenumerationsärenden, ring{' '}
    <a
      className="underline"
      href="tel:0424443000"
      aria-label="Länk med telefonnummer för hjälp med prenumerationsärenden"
    >
      042-444 30 00
    </a>{' '}
    • Ansvarig utgivare Cia Jansson © Copyright {currentYear}{' '}
    <a className="underline" href="https://www.elle.se" aria-label="Länk till Elles startsida">
      elle.se
    </a>
    <br />
    ELLE är en del av{' '}
    <a target="_blank" className="underline" href="https://www.aller.se" aria-label="Länk till information om Aller">
      Aller Media
    </a>
    . Humlegårdsgatan 6, 114 46 Stockholm,{' '}
    <a className="underline" href="tel:0857801000" aria-label="Länk med telefonnummer för kundtjänst">
      08-57 80 10 00
    </a>
  </span>
);

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>

    <Footer.SoMe href="https://www.facebook.com/ellesverige/" name="facebook" aria-label="Länk till Facebook" />
    <Footer.SoMe href="https://www.tiktok.com/@ellesverige" name="tiktok" aria-label="Länk till Tiktok" />
    <Footer.SoMe href="https://www.instagram.com/ellesverige/" name="instagram" aria-label="Länk till Instagram" />
    <Footer.SoMe href="https://www.pinterest.se/ellesverige/" name="pinterest" aria-label="Länk till Pinterest" />
    <Footer.SoMe href="https://www.youtube.com/user/ellesverige" name="youtube" aria-label="Länk till Youtube" />
    <Button
      href="https://news.google.com/publications/CAAqBwgKMKPLpAsw5tW8Aw?hl=sv&gl=SE&ceid=SE%3Asv"
      target="_blank"
      options={{
        className: '!rounded-full',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-5" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

export const ElleStandaloneFooter: typeof StandaloneFooter = () => {
  return <StandaloneFooter {...{ columns, copyright, description, editors, logo, soMe }} />;
};
