const yvesKlein = {
  100: '#E8EDF5',
  200: '#D1DAEC',
  300: '#A2B5D8',
  400: '#8BA3CE',
  500: '#7491C5',
  600: '#5D7EBB',
  700: '#456CB1',
  800: '#2E59A8',
  900: '#17479e',
};

const forestGreen = {
  100: '#DDDFD3',
  200: '#CBD0BD',
  300: '#BAC0A7',
  400: '#A9B091',
  500: '#98A07B',
  600: '#879065',
  700: '#75814F',
  800: '#647139',
  900: '#536123',
};

const lionYellow = {
  100: '#FCEFCE',
  200: '#FAE7B6',
  300: '#F9DF9D',
  400: '#F7D784',
  500: '#F5CF6C',
  600: '#F4C754',
  700: '#F2BF3B',
  800: '#F1B723',
  900: '#EFAF0A',
};

const warmRed = {
  100: '#F3D0D1',
  200: '#ECB8B9',
  300: '#E6A1A2',
  400: '#E0898B',
  500: '#DA7174',
  600: '#D45A5D',
  700: '#CD4245',
  800: '#C72B2E',
  900: '#C11317',
};

const softPurple = {
  100: '#E7E3EC',
  200: '#DBD5E2',
  300: '#CFC7D9',
  400: '#C3B9CF',
  500: '#B7ACC5',
  600: '#AB9EBC',
  700: '#9F90B2',
  800: '#9382A9',
  900: '#87749F',
};

const apricotOrange = {
  100: '#FFF2EC',
  200: '#FFE6D9',
  300: '#FFD9C6',
  400: '#FFCCB3',
  500: '#FFBFA0',
  600: '#FFB38E',
  700: '#FFA67B',
  800: '#FF8D55',
  900: '#FF8042',
};

const softPink = {
  100: '#FFCAC5',
  500: '#FFC4BE',
  900: '#FFBDB7',
};

const lightSand = {
  100: '#FFFDFC',
  500: '#FBF6F0',
  900: '#FAEFE4',
};

const success = {
  100: '#E9FBE9',
  200: '#D4F7D4',
  300: '#ABEEAA',
  400: '#82E380',
  500: '#5FD75D',
  600: '#3DC93B',
  700: '#1DBA1A',
  800: '#10950E',
  900: '#076C05',
};

const error = {
  100: '#FFB4AA',
  200: '#FF897D',
  300: '#FE5449',
  400: '#DE3730',
  500: '#BA1B1A',
  600: '#930109',
  700: '#690105',
  800: '#410002',
  900: '#000000',
};

const gray = {
  50: '#FCFCFC',
  100: '#F3F3F3',
  200: '#E6E6E6',
  300: '#CCCCCC',
  400: '#ACACAC',
  500: '#8D8D8D',
  600: '#717171',
  700: '#5F5F5F',
  800: '#454545',
  900: '#2C2C2C',
  950: '#1F1F1F',
};

const colors = {
  'yves-klein': {
    DEFAULT: yvesKlein[900],
    ...yvesKlein,
  },
  'forest-green': {
    DEFAULT: forestGreen[900],
    ...forestGreen,
  },
  'lion-yellow': {
    DEFAULT: lionYellow[900],
    ...lionYellow,
  },
  'warm-red': {
    DEFAULT: warmRed[900],
    ...warmRed,
  },
  'soft-purple': {
    DEFAULT: softPurple[900],
    ...softPurple,
  },
  'apricot-orange': {
    DEFAULT: apricotOrange[900],
    ...apricotOrange,
  },
  'soft-pink': {
    DEFAULT: softPink[900],
    ...softPink,
  },
  'light-sand': {
    DEFAULT: lightSand[900],
    ...lightSand,
  },
  success: {
    DEFAUlT: success[700],
    ...success,
  },
  error: {
    DEFAUlT: success[500],
    ...error,
  },
  gray: {
    DEFAULT: gray[500],
    ...gray,
  },
};

module.exports = colors;
