import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: ['border-1', 'focus-visible:outline-none', 'focus:ring-2', 'rounded-md', 'uppercase'],
  variants: {
    colors: {
      primary: ['bg-black', 'text-white', 'border-black', 'hover:bg-gray-900', 'focus:ring-gray-400'],
      secondary: ['bg-white', 'border-black', 'hover:bg-gray-200', 'focus:ring-2', 'focus:ring-gray-400'],
    },
    size: {
      small: ['px-1.5', 'gap-0.75', 'pb-0', 'pt-0.75', '[&_svg]:mb-0.75', 'h-7', 'text-button-md'],
      medium: ['px-2', 'gap-1.5', 'pb-0', 'pt-0.75', '[&_svg]:mb-0.75', 'h-8.5', 'text-button-md'],
      large: ['px-4', 'gap-1.75', 'pb-0', 'pt-0.75', '[&_svg]:mb-0.75', 'h-10.5', 'text-button-md'],
    },
  },
});

export default Object.assign(Button, { Icon });
