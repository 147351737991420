const headline = {
  '2xl': {
    font: 'primary',
    size: '108px',
    weight: '200',
    leading: '0.96',
    tracking: '0.7px',
  },
  xl: {
    font: 'primary',
    size: '56px',
    weight: '200',
    leading: '1',
    tracking: '0.5px',
  },
  lg: {
    font: 'primary',
    size: '46px',
    weight: '200',
    leading: '1',
    tracking: '0.5px',
  },
  md: {
    font: 'primary',
    size: '32px',
    weight: '200',
    leading: '1.12',
    tracking: '0.5px',
  },
  sm: {
    font: 'primary',
    size: '24px',
    weight: '200',
    leading: '1.08',
    tracking: '0.5px',
  },
  xs: {
    font: 'primary',
    size: '20px',
    weight: '200',
    leading: '1.1',
    tracking: '0.5px',
  },
  '2xs': {
    font: 'primary',
    size: '18px',
    weight: '200',
    leading: '1.2',
    tracking: '0.5px',
  },
};

const gabor = {
  xl: {
    font: 'secondary',
    size: '32px',
    weight: '400',
    leading: '1.12',
    tracking: '0.5px',
  },
  lg: {
    font: 'secondary',
    size: '24px',
    weight: '400',
    leading: '1.08',
    tracking: '0.5px',
  },
  md: {
    font: 'secondary',
    size: '20px',
    weight: '400',
    leading: '1.1',
    tracking: '0.5px',
  },
  sm: {
    font: 'secondary',
    size: '18px',
    weight: '400',
    leading: '1.1',
    tracking: '0.5px',
  },
};

const quote = {
  lg: {
    font: 'primary',
    size: '54px',
    weight: '275',
    leading: '1.2',
    tracking: '0.3px',
  },
  sm: {
    font: 'primary',
    size: '28px',
    weight: '275',
    leading: '1.1',
    tracking: '0.3px',
  },
};

const preamble = {
  md: {
    font: 'secondary',
    size: '20px',
    weight: '700',
    leading: '1.2',
    tracking: '0.2px',
  },
};

const paragraph = {
  md: {
    font: 'tertiary',
    size: '18px',
    weight: '400',
    leading: '1.4',
    tracking: '0.2px',
  },
};

const preview = {
  lg: {
    font: 'secondary',
    size: '20px',
    weight: '400',
    leading: '1.2',
    tracking: '0.3px',
  },
  sm: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.25',
    tracking: '0.3px',
  },
};

const label = {
  md: {
    font: 'secondary',
    size: '12px',
    weight: '400',
    leading: '1',
    tracking: '0.3px',
  },
};

const tag = {
  md: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.1',
    tracking: '0.8px',
  },
};

const button = {
  md: {
    font: 'secondary',
    size: '16px',
    weight: '400',
    leading: '1.1',
    tracking: '0.3px',
  },
};

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  gabor: {
    DEFAULT: gabor.md,
    ...gabor,
  },
  quote: {
    DEFAULT: quote.lg,
    ...quote,
  },
  preamble: {
    DEFAULT: preamble.md,
    ...preamble,
  },
  paragraph: {
    DEFAULT: paragraph.md,
    ...paragraph,
  },
  preview: {
    DEFAULT: preview.lg,
    ...preview,
  },
  label:{
    DEFAULT: label.md,
    ...label,
  },
  tag:{
    DEFAULT: tag.md,
    ...tag,
  },
  button: {
    DEFAULT: button.md,
    ...button,
  },
}

module.exports = typography;
