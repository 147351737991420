import { Button } from '@/components/Button';
import { FrontCoverstory } from '@/components/FrontCoverstory';
import { Icon } from '@/components/Icon';
import ElleLogo from '@/elle/public/image/elle-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useAppState } from 'lib/hooks/useAppState';
import useUserAuth, { UserAuthProps } from 'lib/hooks/useUserAuth';
import { useRouter } from 'next/router';

const logo: StandaloneNavigationProps['logo'] = <ElleLogo />;

const menu: StandaloneNavigationProps['menu'] = [
  { href: '/mode', text: 'MODE' },
  { href: '/beauty', text: 'BEAUTY' },
  { href: '/decoration', text: 'DECORATION' },
  { href: '/mat-och-vin', text: 'MAT & VIN' },
  { href: '/video', text: 'VIDEO' },
  { href: '/bloggar', text: 'BLOGGAR' },
  { href: '/member', text: 'MEMBER' },
].map(({ href, text }) => (
  <Navigation.Link
    key={href}
    href={href}
    options={{
      size: 'drawer',
      variant: 'bar',
    }}
    content={text}
  />
));

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group>
    <Navigation.SoMe.Headline>Följ ELLE</Navigation.SoMe.Headline>
    <Navigation.SoMe
      href="https://www.facebook.com/ellesverige/"
      name="facebook"
      aria-label="Länk till Facebook"
      options={{ colors: 'border' }}
    />
    <Navigation.SoMe
      href="https://www.tiktok.com/@ellesverige"
      name="tiktok"
      aria-label="Länk till Tiktok"
      options={{ colors: 'border' }}
    />
    <Navigation.SoMe
      href="https://www.instagram.com/ellesverige/"
      name="instagram"
      aria-label="Länk till Instagram"
      options={{ colors: 'border' }}
    />
    <Navigation.SoMe
      href="https://www.pinterest.se/ellesverige/"
      name="pinterest"
      aria-label="Länk till Pinterest"
      options={{ colors: 'border' }}
    />
    <Button
      href="https://news.google.com/publications/CAAqBwgKMKPLpAsw5tW8Aw?hl=sv&gl=SE&ceid=SE%3Asv"
      target="_blank"
      options={{
        className: '!rounded-full',
        colors: 'secondary',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-5" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Navigation.SoMe.Group>
);

type GetDrawerProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logOutUrl' | 'myPageUrl'>;

const getDrawer = ({ isUserLoggedIn, logOutUrl, myPageUrl }: GetDrawerProps) =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          {
            href: myPageUrl,
            content: (
              <div className="flex items-center gap-1">
                MINA INSTÄLLNINGAR
                <Icon name="externalLink" />
              </div>
            ),
            options: {
              className: isUserLoggedIn ? '' : 'hidden',
              rel: 'nofollow',
            },
          },
          { href: '/mode', content: 'MODE' },
          { href: '/beauty', content: 'BEAUTY' },
          { href: '/elle-galan', content: 'ELLE-GALAN' },
          [
            { href: '/decoration', content: 'DECORATION' },
            { href: '/decoration/hemma-hos', content: '– Hemma hos' },
            { href: '/decoration/gor-det-sjalv', content: '– Gör det själv' },
            { href: '/decoration/tradgard', content: '– Trädgård' },
          ],
        ],
      },
      {
        links: [
          [
            { href: '/mat-och-vin', content: 'MAT & VIN' },
            { href: '/mat-och-vin/mat', content: '– Mat' },
            { href: '/mat-och-vin/dryck', content: '– Dryck' },
            { href: '/mat-och-vin/bakning', content: '– Bakning' },
            { href: '/etikett/vegetariskt', content: '– Vegetariskt' },
            { href: '/etikett/recept', content: '– Alla recept' },
          ],
          { href: '/video', content: 'VIDEO' },
          { href: '/bloggar', content: 'BLOGGAR' },
        ],
      },
      {
        links: [
          { href: '/member', content: 'MEMBER' },
          [
            { href: '/horoskop', content: 'HOROSKOP' },
            { href: '/etikett/dagens%20horoskop', content: '– Dagens' },
            { href: '/etikett/veckans%20horoskop', content: '– Veckans' },
            { href: '/etikett/m%C3%A5nadens%20horoskop', content: '– Månadens' },
            { href: '/horoskop/arets-horoskop-2025/10493173', content: '– Årets' },
          ],
          { href: '/noje', content: 'NÖJE' },
          { href: '/lifestyle', content: 'LIFESTYLE' },
          { href: '/halsa', content: 'HÄLSA' },
          { href: '/resor', content: 'RESOR' },
          {
            href: 'https://prenumerera.se/kampanj/?campaign=S11P59&utm_source=elle.se&utm_medium=menu&utm_campaign=prenumereration',
            content: 'PRENUMERERA',
          },
        ],
      },
      {
        links: [
          { href: '/nyhetsbrev', content: 'NYHETSBREV' },
          { href: '/kontakt', content: 'KONTAKT' },
          { href: '/om-oss', content: 'OM OSS' },
          { href: '/om-cookies', content: 'OM COOKIES' },
          {
            href: '#',
            content: 'HANTERA PREFERENSER',
            options: {
              onClick: (e: MouseEvent) => {
                showOneTrustConsent();
                e.preventDefault();
              },
            },
          },
          { href: '/alla-amnen', content: 'ALLA ÄMNEN' },
          { href: '/vara-skribenter', content: 'VÅRA SKRIBENTER' },
          { href: '/creative-studio', content: 'CREATIVE STUDIO' },
          {
            href: logOutUrl,
            content: 'LOGGA UT',
            options: {
              className: isUserLoggedIn ? '' : 'hidden',
              rel: 'nofollow',
            },
          },
        ],
      },
    ],
  });

export const ElleStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isUserLoggedIn, logInUrl, logOutUrl, myPageUrl } = useUserAuth();
  const { asPath } = useRouter();
  const isMyPage = asPath.startsWith('/mina-sidor');

  const [{ coverstory }] = useAppState();

  const controls: StandaloneNavigationProps['controls'] = (
    <>
      <Navigation.Button
        key="prenumerera"
        href="https://prenumerera.se/kampanj/?campaign=S11P62&utm_source=elle.se&utm_medium=menu&utm_campaign=prenumereration"
        target="_blank"
        options={{ colors: 'secondary' }}
        content="Prenumerera"
      />
      <Navigation.Button
        key="login"
        href={isUserLoggedIn ? '/mina-sidor' : logInUrl}
        target={isUserLoggedIn ? '_self' : '_blank'}
        options={{ rel: 'nofollow' }}
        content={
          <>
            <span className={'hidden xs:block'}>{isUserLoggedIn ? 'Min Sida' : 'Logga in'}</span>
            <div className="grid h-6 w-6 place-items-center rounded-full xs:hidden">
              <Icon name="user" options={{ className: 'text-white', size: 'small' }} />
            </div>
          </>
        }
      />

      <button type="button" onClick={showOneTrustConsent}>
        <Icon name="cookie" options={{ className: 'text-black w-7.5 h-7.5 sm:w-6 sm:h-6' }} />
      </button>
    </>
  );

  const drawer = getDrawer({ isUserLoggedIn, logOutUrl, myPageUrl });

  return (
    <>
      {coverstory && (
        <FrontCoverstory
          link={coverstory.link}
          title={coverstory.title}
          subtitle={coverstory.subtitle}
          mediaUrl={coverstory.mediaUrl}
          options={{
            $logo: {
              className: coverstory.logoColorClassName,
            },
          }}
        />
      )}
      <StandaloneNavigation
        options={{ $search: { $submit: { options: { size: 'large' } } } }}
        {...{ logo, menu, controls, drawer, soMe }}
      />
    </>
  );
};
