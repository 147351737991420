import { tw } from '@/utils/tw';

const Link = tw.theme({
  base: [
    'bg-white',
    'overflow-hidden',
    'relative',
    'block',
    'z-[101]',
    'max-h-full',
    'data-[hidden=true]:max-h-0',
    'transition-[max-height]',
    'data-[hidden=false]:delay-150',
  ],
});

const FrontCoverstory = tw.theme({});

export default Object.assign(FrontCoverstory, { Link });
