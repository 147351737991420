import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const { Button } = BaseNavigationTheme;

const BaseLinkVariants = BaseNavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-black'],
      drawer: BaseLinkVariants.colors.drawer,
    },
    size: {
      bar: ['p-2.5', 'lg:p-2', 'text-body-md', 'px-3', 'py-1'],
      controls: BaseLinkVariants.size.controls,
      drawer: ['py-0'],
    },
    variant: {
      bar: ['text-preview-sm', 'block', 'pt-2', 'pb-1', 'whitespace-nowrap'],
      drawerPrimary: ['font-medium', 'text-preview-sm', 'block', 'my-3.5', 'sm:my-2'],
      drawerSecondary: ['text-preview-sm', 'block', 'py-1.5', 'whitespace-nowrap', 'last-of-type:sm:mb-8'],
    },
  },
});

export default Object.assign({}, { Button, Link });
