import ElleLogo from '@/elle/public/image/elle-logo.svg';
import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useComponentsState } from 'lib/hooks/useComponentsState';
import { FrontCoverstory } from './FrontCoverstory';
import { FrontCoverstoryImageProps } from './FrontCoverstory.Image';

type Extras = {
  $image?: FrontCoverstoryImageProps;
};

export interface ElleStandaloneFrontCoverstoryProps extends StandaloneComponentProps<typeof FrontCoverstory, Extras> {
  link?: string;
  title?: string;
  subtitle?: string;
  mediaUrl?: {
    mobile?: string;
    desktop?: string;
  };
}

export const ElleStandaloneFrontCoverstory: StandaloneComponent<ElleStandaloneFrontCoverstoryProps> = ({
  link,
  title,
  subtitle,
  mediaUrl,
  options,
  ...props
}) => {
  const [{ device }] = useAppState();

  const { $caption, $description, $group, $image, $video, $logo, ...$base } = options ?? {};

  const { $standalone: imageStandaloneProps, ...imageProps } = $image ?? {};

  const {
    Navigation: {
      $drawer: { isVisible: isMenuDrawerOpened },
    },
  } = useComponentsState();

  const src = mediaUrl?.[device ?? 'desktop'] ?? mediaUrl?.desktop;

  return (
    // @ts-expect-error: `FrontCoverstory` is non-slot architecture
    <FrontCoverstory
      data-hidden={isMenuDrawerOpened}
      href={link}
      content={
        <>
          {src?.includes('mp4') ? (
            <FrontCoverstory.Video videoURL={src} data-hidden={isMenuDrawerOpened} {...$video} />
          ) : (
            <FrontCoverstory.Image
              $standalone={{ src, alt: title, ...imageStandaloneProps }}
              data-hidden={isMenuDrawerOpened}
              {...imageProps}
            />
          )}

          <FrontCoverstory.Logo data-hidden={isMenuDrawerOpened} {...$logo}>
            <ElleLogo />
          </FrontCoverstory.Logo>

          <FrontCoverstory.Group {...$group}>
            {title && <FrontCoverstory.Caption {...$caption}>{title}</FrontCoverstory.Caption>}
            {subtitle && <FrontCoverstory.Description {...$description}>{subtitle}</FrontCoverstory.Description>}
          </FrontCoverstory.Group>
        </>
      }
      {...$base}
      {...props}
    />
  );
};
