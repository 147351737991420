import { Link as StandaloneLink } from '@/components/Link';
import { GenericSlot } from 'base/components/GenericSlot';
import { FrontCoverstoryCaptionComponent } from './FrontCoverstory.Caption';
import { FrontCoverstoryDescriptionComponent } from './FrontCoverstory.Description';
import { FrontCoverstoryGroupComponent } from './FrontCoverstory.Group';
import { FrontCoverstoryImageComponent } from './FrontCoverstory.Image';
import { FrontCoverstoryLogoComponent } from './FrontCoverstory.Logo';
import { FrontCoverstoryVideoComponent } from './FrontCoverstory.Video';
import FrontCoverstoryTheme from './theme';

const Base = GenericSlot({ as: StandaloneLink, theme: FrontCoverstoryTheme.Link });

export const FrontCoverstory = Object.assign(Base, {
  Caption: FrontCoverstoryCaptionComponent,
  Description: FrontCoverstoryDescriptionComponent,
  Group: FrontCoverstoryGroupComponent,
  Image: FrontCoverstoryImageComponent,
  Video: FrontCoverstoryVideoComponent,
  Logo: FrontCoverstoryLogoComponent,
});
