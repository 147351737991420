const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-links': theme('colors.black'),
        '--tw-prose-bullets': theme('colors.black'),
        '--tw-prose-counters': theme('colors.black'),

        'max-width': '100%',

        ...typograph('paragraph-md'),

        h1: {
          ...typograph('headline-lg'),
          marginBottom: theme('spacing[5]'),

          '@media screen(sm)': typograph('headline-md'),
        },

        h2: {
          ...typograph('gabor-lg'),
          textTransform: 'uppercase',
          marginBottom: theme('spacing[1]'),
          marginTop: theme('spacing[8]'),

          '@media screen(sm)': {
            marginBottom: theme('spacing[2]'),
          },
        },

        h3: {
          ...typograph('preview-lg'),
          marginBottom: theme('spacing[1]'),
        },

        h4: {
          ...typograph('preview-sm'),
          marginBottom: theme('spacing[1]'),

          '@media screen(sm)': typograph('preview-lg'),
        },

        h5: {
          ...typograph('preview-sm'),
          marginBottom: theme('spacing[1]'),
        },

        h6: {
          ...typograph('preview-sm'),
          marginBottom: theme('spacing[1]'),

          '@media screen(sm)': typograph('headline-xs'),
        },

        a: {
          '&:hover': {
            color: theme('colors.yves-klein.900'),
          },
          '&:visited': {
            color: theme('colors.black'),
          },
        },

        p: {
          ...typograph('paragraph-md'),
          marginBottom: theme('spacing[3]'),
          marginTop: theme('spacing[3]'),
        },

        ol: {
          marginBottom: theme('spacing[3]'),
        },

        ul: {
          marginBottom: theme('spacing[3]'),
        },

        li: {
          ...typograph('preview-sm'),
          margin: 0,
          paddingLeft: 0,

          '@media screen(sm)': typograph('preview-lg'),
        },
      },
    },

    coverbox: {
      css: {
        ...typograph('preview-sm'),

        p: {
          ...typograph('preview-sm'),
          marginBottom: 0,

          a: {
            fontWeight: '500',
            marginBottom: 0,
            textDecoration: 'none',
          },
        },
      },
    },

    factbox: {
      css: {
        h2: {
          ...typograph('gabor-md'),
          marginBottom: theme('spacing[0.5]'),
        },

        p: {
          ...typograph('preview-lg'),
          marginBottom: theme('spacing[5]'),
        },

        li: {
          ...typograph('preview-lg'),

          '&::marker': {
            color: 'black',
          },
        },
      },
    },
  };
};
